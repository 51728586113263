<template>
    <v-container fluid class="py-0" >
        <v-row
        align="center"
        justify="center"
        
        :style="'background-image:url(\'/storage/app/media/'+img+'\'); background-size:cover;height:250px;'" 
        class="d-flex align-items-center mb-0"
        >
            <v-col
                class="text-center white--text bg-overlay"
                cols="12"
            >
                <h1 class="display-1 font-weight-thin mb-4 ">
                {{title}}
                </h1>
                <h4 class="subheading">
                {{subtitle}}
                </h4>
            </v-col>
        </v-row>
    
    </v-container>
</template>
<script>
export default {
  name: 'BannerImage',
  props: ['title','subtitle','img']
}    
</script>
<style>
.bg-overlay{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0,0,0,0.38);
}
</style>