<template>
    <v-row no-gutters>
        <!--<v-row no-gutters class="my-5 mx-4">
            <v-col class="text-center py-4 d-flex flex-column">
                <v-icon  class="mb-2">mdi-account-multiple-outline</v-icon>
                <small>5 personnes</small>
            </v-col>
            <v-col class="text-center py-4 d-flex flex-column">
                <v-icon  class="mb-2">mdi-bed-king-outline</v-icon>
                <small>4 Chambres</small>
            </v-col>
            <v-col class="text-center py-4 d-flex flex-column">
                <v-icon  class="mb-2">mdi-calendar-outline</v-icon>
                <small>13 Mar - 20 Mar 2022</small>
            </v-col>
        </v-row>-->
        <v-stepper
            v-model="e6"
            vertical
            class="mt-4"
        >
            <v-stepper-step
            :complete="e6 > 1"
            editable
            step="1"
            >
            Vérifiez vos informations personnelles
            </v-stepper-step>

            <v-stepper-content step="1">
            <v-card
                elevation="0"
                color="lighten-1"
                class="pl-0 pr-1 py-3"
            >
            <v-col 
                    cols="12"
                    md="12"
                    class="mb-5 pt-0 pb-4"
                >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Culpa error deserunt unde, blanditiis aliquid quaerat asperiores architecto ab quisquam ullam, rem accusamus, iste vitae recusandae incidunt sapiente? Quis, error. Dicta.
            </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled
                    v-model="firstname"
                    :rules="nameRules"
                    prepend-icon="mdi-account-outline"

                    label="Nom"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled
                    v-model="lastname"
                    :rules="nameRules"
                    prepend-icon="mdi-account-outline"
                    label="Prénom"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled
                    v-model="phonenumber"
                    :rules="nameRules"
                    prepend-icon="mdi-phone-outline"
                    label="Téléphone"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled 
                    v-model="email"
                    :rules="nameRules"
                    prepend-icon="mdi-at"
                    label="Email"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled 
                    v-model="address"
                    :rules="nameRules"
                    prepend-icon="mdi-home-outline"
                    label="N° et voie"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled 
                    v-model="zipcode"
                    :rules="nameRules"
                    prepend-icon="mdi-home-outline"
                    label="Code postal"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled 
                    v-model="city"
                    :rules="nameRules"
                    prepend-icon="mdi-home-outline"
                    label="Ville"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    class="py-0"
                >
                    <v-text-field dense  outlined filled 
                    v-model="country"
                    :rules="nameRules"
                    prepend-icon="mdi-home-outline"
                    label="Pays"
                    required
                    ></v-text-field>
                </v-col>
            </v-card>
            <v-divider class="my-4"></v-divider>
            <v-col class="text-right">
                <v-btn text>
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="e6 = 2"
                >
                    Continue
                </v-btn>
                
            </v-col>
            </v-stepper-content>

            <v-stepper-step
            :complete="e6 > 2"
            editable
            step="2"
            >
            Complétez les informations de vos occupants
            </v-stepper-step>

            <v-stepper-content step="2">
            <v-card
                elevation="0"
                color="lighten-1"
                class="pa-1"
            >
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(occupant, i) in occupants" :key="i">
                    <v-expansion-panel-header v-slot="{ open }">
                        <v-row  no-gutters class="align-center" >
                            <v-col cols="4"  class=" d-flex align-center">
                                <v-icon color="primary" class="mr-2" >mdi-account</v-icon>
                                <span># {{i+1}}</span>
                            </v-col>
                            <v-col
                            cols="8"
                            class="text--secondary"
                            >
                            <v-fade-transition leave-absolute>
                                <span
                                v-if="open"
                                key="0"
                                >
                                Nom de l'occupant
                                </span>
                                <span
                                v-else
                                key="1"
                                >
                                {{ occupant.name }} {{ occupant.lastname }}
                                </span>
                            </v-fade-transition>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-text-field
                        :value="occupant.name"
                        placeholder="Nom"
                        ></v-text-field>
                        <v-text-field
                        :value="occupant.lastname"
                        placeholder="Prénom"
                        ></v-text-field>
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
            </v-card>
            <v-divider class="my-4"></v-divider>
            <v-col class="text-right">
                <v-btn text>
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="e6 = 3"
                >
                    Continue
                </v-btn>
            </v-col>
            </v-stepper-content>

            <v-stepper-step
            :complete="e6 > 3"
            editable
            step="3"
            >
            Renseignez vos informations d'arrivée
            </v-stepper-step>

            <v-stepper-content step="3">
            <v-card
                elevation="0"
                color="lighten-1"
                class="pa-4"
            >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nemo expedita, neque quo dolore numquam fugit, possimus enim minima voluptates repudiandae quas dolorum excepturi, ab magni tempora accusamus. Nostrum, laboriosam nobis.
            </v-card>
            <v-divider class="my-4"></v-divider>
            <v-col class="text-right">
                <v-btn text>
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="e6 = 4"
                >
                    Continue
                </v-btn>
            </v-col>
            </v-stepper-content>

            <v-stepper-step step="4" editable>
            View setup instructions
            </v-stepper-step>
            <v-stepper-content step="4">
            <v-card
                elevation="0"
                color="lighten-1"
                class="pa-4"
            >
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nemo expedita, neque quo dolore numquam fugit, possimus enim minima voluptates repudiandae quas dolorum excepturi, ab magni tempora accusamus. Nostrum, laboriosam nobis.
            </v-card>
            <v-divider class="my-4"></v-divider>
            <v-col class="text-right">
                <v-btn text>
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    @click="e6 = 1"
                >
                    Continue
                </v-btn>
                
            </v-col>
            </v-stepper-content>
        </v-stepper>
    </v-row> 
</template>

<script>
  export default {
    data () {
      return {
        e6: 1,
        firstname:"Palomba",
        lastname:"Lucas",
        phonenumber: "06 21 51 11 34",
        email: "l.palomba@alpine-lodges.fr",
        occupants: [ 
            {
                name: "",
                lastname: ""
            },
            {
                name: "Palomba",
                lastname: "Lucas"
            },
        ]
      }
    },
  }
</script>