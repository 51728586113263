<template>
 <v-container class=" home" >
    <v-row class="mx-0">
      <v-col class="text-center ">
          <BannerImage v-if="this.$session.get('authToken')" title="Bonjour Lucas !" subtitle="Votre séjour débute dans seulement 5 jours" img="AR-Kinabalu.jpg"/>
          <BannerImage v-else title="Accéder à mon pré-checkin" subtitle="Votre séjour débute bientôt ?" img="AR-Kinabalu.jpg"/>
      </v-col>
    </v-row>

    <BookingPrecheckin v-if="this.$session.get('authToken')" />   
    <FormAuthPrecheckin v-else />
    


  </v-container>

</template>
<style>
.mosaic__links{
  text-decoration: none;
}
.has-overlay-gradient {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.5)25%, transparent 75%);
}

</style>
<script>
// @ is an alias to /src
import BannerImage from '@/components/BannerImage.vue'
import FormAuthPrecheckin from '@/components/FormAuthPrecheckin.vue'
import BookingPrecheckin from '@/components/BookingPrecheckin.vue'
import axios from 'axios'

export default {
  name: 'home',
  props: ['etab'],
  components: {
    BannerImage, FormAuthPrecheckin, BookingPrecheckin
  },
  beforeRouteEnter (to, from, next) {
    axios.get('/etab/' + to.params.etab)
          .then((response) => {
            next(vm => vm.setData(null,response.data))
          })
          .catch((err) => {
            next(vm => vm.setData(err, null))
          })
    
  }, 
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
    this.content = null
    axios.get('/etab/' + to.params.etab)
      .then((response) => {
        this.setData(null,response.data)
        next()
      })
      .catch((err) => {
        this.setData(err, null)
        next()
      })

  },
  data () {
    return {
      loading: false,
      error: null,
      content: null
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.error = this.content = null
      this.loading = true
      axios.get('/etab/' + this.etab).then(response => {
        this.content =  response.data
        this.loading = false
        document.title = `Alpine Resorts - Room Directory - `
      })
      
    },
    setData (err, content) {
      if (err) {
        this.error = err.toString()
      } else {
        this.content = content
        document.title = `Alpine Resorts - Room Directory - `
      }
    }
  }
}
</script>
