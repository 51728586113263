<template>
    <v-row dense class="mx-0">
        <v-col>
            <div class="pa-5 pb-0 text-center">
                Connectez-vous pour finaliser l'organisation de vos vacances avec Alpine Resorts<br>
                Pour vous connecter, rien de plus simple : utilisez votre nom, ainsi que la référence de réservation qui vous a été fournie et la date de début de votre séjour.
            </div>
            <v-form v-model="valid" class="px-5 py-3">
            <v-container>
                <v-row class="pl-0 pr-2">
                <v-col
                    cols="12"
                    md="12"
                >
                    <v-text-field outlined
                    v-model="firstname"
                    :rules="nameRules"
                    prepend-icon="mdi-account-search-outline"
                    hint="Le nom utilisé pour la réservation"
                    persistent-hint
                    label="Nom de famille"
                    required
                    ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    md="12"
                >
                    <v-text-field outlined
                    v-model="lastname"
                    :rules="nameRules"
                    prepend-icon="mdi-book-outline"
                    hint="Votre numero de réservation apparait sur votre document de réservation"
                    persistent-hint
                    label="N° de Réservation"
                    required
                    ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    md="12"
                    
                >
                    <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="startdate"
                    persistent
                    width="90%"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined
                        v-model="startdate"
                        :rules="startdateRules"
                        label="Date d'arrivée"
                        prepend-icon="mdi-calendar-check"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        width="100%"
                        v-model="startdate"
                        show-adjacent-months
                        scrollable
                        locale="fr-fr"
                        color="var(--color-primary)"
                        
                        
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                        >
                        Cancel
                        </v-btn>
                        <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(startdate)"
                        >
                        OK
                        </v-btn>
                    </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" md="12" class="text-center">
                    <v-btn
                    :disabled="!valid"
                    color="success"
                    
                    @click="validate"
                    >
                    Accèder
                    </v-btn>
                </v-col>

                </v-row>
            </v-container>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>



export default {
    data () {
        return {
        loading: false,
        error: null,
        content: null,
        valid: false,
        modal: false,
        firstname: '',
        lastname: '',
        nameRules: [
            v => !!v || 'Ce champs est requis'
        ],
        startdate: '',
        startdateRules: [
            v => !!v || 'Ce champs est requis'
        ],
        }
    },
}
</script>